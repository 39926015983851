import { Component, Inject, OnInit, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DomService } from '@services/dom.service';
import { LoggerService } from '@services/logger.service';

import { StoreFillInCommand } from './commands/store-fill-in.command';
import { RtlService } from '@services/rtl.service';
import { DOCUMENT } from '@angular/common';
import { EventsService, EventType } from './services/events.service';
import { SyncWaitService } from './services/sync-wait.service';
import { AppStore } from './app.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private storeFillInCommand: StoreFillInCommand,
    translateService: TranslateService,
    domService: DomService,
    loggerService: LoggerService,
    private rtlService: RtlService,
    @Inject(DOCUMENT) private doc: Document,
    private eventsService: EventsService,
    private syncWaitService: SyncWaitService,
    private appStore: AppStore,
  ) {
    const defaultLang = 'en';
    const language = domService.getURLParam('l') ?? translateService.getBrowserLang() ?? defaultLang;
    const logLevel = Number.parseInt(domService.getURLParam('ll')!, 10);

    loggerService.setLogLevel(logLevel);

    this.setDir(language);

    translateService.setDefaultLang(defaultLang);
    translateService.use(language);
  }

  ngOnInit(): void {
    this.storeFillInCommand.execute();
  }

  @HostListener('document:visibilitychange')
  visibilityChangeHandler(): void {
    if (this.doc.visibilityState === 'hidden' && !this.appStore.permanentlyIgnoreVisibilityListener$.getValue()) {
      this.eventsService.publish({
        type: EventType.Track,
        action: 'Changed Webapp Focus (Switch or Close Tab)',
        source: this.appStore.currentSource$.getValue(),
      });
    }

    this.syncWaitService.run(300);
  }

  setDir(language: string): void {
    this.doc.dir = this.rtlService.isRtlLang(language) ? 'rtl' : 'ltr';
  }
}
