import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { IonicModule, mdTransitionAnimation } from '@ionic/angular';

import { ApiInterceptor } from '@repositories/interceptors/api.interceptor';
import { DomService } from '@services/dom.service';
import { LoggerService } from '@services/logger.service';
import { PersistentStorageService } from '@services/persistent-storage.service';
import { RtlService } from '@services/rtl.service';

import { CookiesRepository } from '@repositories/cookies-repository.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GlobalErrorHandler } from './infrastructure/global-error-handler';
import { AppStore } from './app.store';
import { BaseComponent } from './shared/base/base.component';
import { StoreFillInCommand } from './commands/store-fill-in.command';
import { environment } from '../environments/environment';
import { EventsService } from '@services/events.service';
import { SignStore } from './pages/sign/sign.store';
import { SyncWaitService } from '@services/sync-wait.service';
import { iosTransitionAnimation, TransitionOptions } from '@services/ios.transition';
import { TranslateLoaderService } from '@services/translate-loader.service';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient): TranslateLoaderService {
  return new TranslateLoaderService(http, environment.translationsUrlPrefix, environment.translationsUrlSuffix);
}

@NgModule({
  declarations: [AppComponent, BaseComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    IonicModule.forRoot({
      navAnimation: (navEl: HTMLElement, opts: TransitionOptions) =>
        opts.mode === 'ios' ? iosTransitionAnimation(navEl, opts) : mdTransitionAnimation(navEl, opts),
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    AppStore,
    LoggerService,
    EventsService,
    SyncWaitService,
    SignStore,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    DomService,
    StoreFillInCommand,
    PersistentStorageService,
    CookiesRepository,
    RtlService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
