import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppStore } from '../app.store';
import { SignStore } from '../pages/sign/sign.store';
import { DomService } from '@services/dom.service';

export interface IEvent {
  type: EventType;
  action?: string;
  source?: string;
  errorMessage?: string;
  metadata?: unknown;
}

export enum EventType {
  Click = 'click',
  Track = 'track',
  PageView = 'pageView',
  Error = 'error',
}

enum CategoryMap {
  register = 'registration',
  login = 'login',
  link = 'link',
  linkonlogin = 'login',
}

@Injectable()
export class EventsService {
  constructor(
    private httpClient: HttpClient,
    private appStore: AppStore,
    private signStore: SignStore,
    private domService: DomService,
  ) {}

  async publish(event: IEvent): Promise<void> {
    // @ts-ignore
    const version = window.appVersion;
    const userAgent = window.navigator.userAgent;
    const context = this.signStore.context$.getValue() || this.domService.getChallengeFromUrl();
    const category = CategoryMap[this.signStore.initialChallengeType$.getValue()!];

    event.metadata = event.metadata ?? {};

    // @ts-ignore
    event.metadata.origin = this.appStore.isFromDesktop$.getValue() ? 'Desktop' : 'Other';

    return firstValueFrom(
      this.httpClient
        .post(`${this.appStore.backendUrl$.getValue() ?? ''}/events`, {
          ...event,
          version,
          component: 'webApp',
          userAgent,
          context,
          category,
          sourceTimestamp: Date.now().toString(),
        })
        .pipe(
          catchError(() => of(void 0)),
          map(() => {}),
        ),
    );
  }
}
