import { TranslateLoader } from '@ngx-translate/core';
import { Observable, retry } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';

export class TranslateLoaderService implements TranslateLoader {
  private static readonly defaultPrefix = '/assets/i18n/';

  private static readonly defaultSuffix = '.json';

  constructor(private http: HttpClient, private prefix?: string, private suffix?: string) {}

  public getTranslation(lang: string): Observable<Object> {
    return this.http.get(`${this.prefix}${lang}${this.suffix}`).pipe(
      retry(3),
      catchError(() => {
        return this.http.get(`${TranslateLoaderService.defaultPrefix}en${TranslateLoaderService.defaultSuffix}`);
      }),
    );
  }
}
