import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { IConnections } from './shared/i-connection';

@Injectable()
export class AppStore {
  readonly connections$ = new BehaviorSubject<IConnections>({});

  readonly isIos$ = new BehaviorSubject<boolean>(false);

  readonly iosVersion$ = new BehaviorSubject<number>(0);

  readonly isFromDesktop$ = new BehaviorSubject<boolean>(false);

  readonly backendUrl$ = new BehaviorSubject<string | null>(null);

  readonly currentSource$ = new BehaviorSubject<string | undefined>(undefined);

  readonly permanentlyIgnoreVisibilityListener$ = new BehaviorSubject<boolean>(false);
}
