import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseRepository } from './base-repository';
import { environment } from '../../environments/environment';

@Injectable()
export class CookiesRepository extends BaseRepository {
  cookiesURL = `${environment.serverURL}/cookies`;

  constructor(httpClient: HttpClient) {
    super(httpClient);
  }

  convertCookies(requesterId: string): Observable<void> {
    const options = {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    const body = {
      requesterId,
    };

    return this.post<void>(`${this.cookiesURL}/conversion`, body, options);
  }

  deleteConnectionCookies(requesterId: string): Observable<void> {
    const options = {
      withCredentials: true,
    };

    return this.delete<void>(`${this.cookiesURL}/connection/${requesterId}`, options);
  }
}
