import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

if (environment.production) {
  enableProdMode();
}

if (['dev', 'staging', 'uat'].includes(environment.envName)) {
  datadogRum.init({
    applicationId: 'fb5ba58e-71d5-48b1-8492-d105706c6169',
    clientToken: 'pub459b1bf250af80cb955b40e3e7d9935c',
    site: 'datadoghq.com',
    service: 'web-app',
    env: environment.envName,
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'allow',
  });

  datadogRum.startSessionReplayRecording();
}

datadogLogs.init({
  clientToken: 'pub459b1bf250af80cb955b40e3e7d9935c',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: 100,
  env: environment.envName,
  service: 'web-app',
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((error) => console.error(error));
