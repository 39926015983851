import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChallengeTypes, IExtAuthenticatorData, IRequester, IStep } from './i-challenge';
import { IErrorRS } from './i-error';

@Injectable()
export class SignStore {
  // @ts-ignore
  readonly currentStep$ = new BehaviorSubject<IStep>(null);

  // @ts-ignore
  readonly requester$ = new BehaviorSubject<IRequester>(null);

  // @ts-ignore
  readonly connectionName$ = new BehaviorSubject<string>(null);

  readonly extAuthenticatorData$ = new BehaviorSubject<IExtAuthenticatorData | null>(null);

  readonly errors$ = new BehaviorSubject<IErrorRS>({ fieldErrors: {}, generalErrors: [] });

  readonly registrationLoader$ = new BehaviorSubject<boolean>(false);

  readonly context$ = new BehaviorSubject<string | null>(null);

  readonly initialChallengeType$ = new BehaviorSubject<ChallengeTypes | null>(null);

  readonly animateSuccess$ = new BehaviorSubject<boolean>(false);

  readonly animateOnSuccess$ = new BehaviorSubject<boolean>(false);
}
