import { Injectable } from '@angular/core';

@Injectable()
export class PersistentStorageService {
  private storage: Storage = localStorage;

  setStorage(storage: Storage): void {
    this.storage = storage;
  }

  has(key: string): boolean {
    return this.storage.getItem(key) !== null;
  }

  remove(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getItem(key: string): any {
    const rawValue = this.storage.getItem(key);
    let value;

    try {
      value = rawValue !== null ? JSON.parse(rawValue) : undefined;
    } catch {
      value = undefined;
    }

    return value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
  setItem(key: string, value: any): void {
    return this.storage.setItem(key, JSON.stringify(value));
  }
}
